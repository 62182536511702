* {
    padding: 0;
    margin: 0;
    top: 0;
    overflow-x: hidden;
}

html {
    scroll-behavior: smooth;
    font-family: 'KoHo',
        sans-serif;
}

.aboutContainer h2 {
    color: #f15a24;
    font-size: 30px;
    font-weight: 900;
    text-align: center;
    padding: 40px 0 10px;
}

.underLine {
    width: 100px;
    margin: auto;
    border: 0;
    height: 1px;
    background: linear-gradient(to right, rgba(0, 0, 0, 0), #666, rgba(0, 0, 0, 0));
}

.aboutContainer {
    width: 100%;
}



/* Our Company */

.heading {
    justify-content: center;
    margin: auto;
}

.aboutContainer h1 {
    color: #0071bc;
    font-weight: bold;
    font-size: 40px;
    text-align: center;
    padding: 40px 0 0 0;
    margin: 0 auto;

}

.mainContent {
    margin: auto;
    width: 100%;
    display: grid;
    grid-template-columns: auto auto;
    grid-gap: 10px;
}

.m-left,
.m-right {
    width: 100%;
    padding: 20px 40px;
    text-align: center;
}

.m-left p {
    color: #666;
    font-size: 20px;
    font-weight: 500;
    padding: 20px 80px;
    text-align: center;
    justify-content: center;
    line-height: 1.5;
}

.m-right .companyImg {
    width: 90%;
    height: 99%;
    align-items: center;
    justify-content: center;
    display: flex;
}

.m-right img {
    display: block;
    width: 90%;
    height: 80%;
    margin: 40px 35px;
    justify-content: center;
    box-shadow: 10px 10px 20px grey;
    border-radius: 10px;
}


.line {
    width: 100%;
    position: relative;
}

hr.style-line {
    width: 50%;
    margin: 3% auto;
    border: 0;
    height: 1px;
    background: linear-gradient(to right, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.75), rgba(0, 0, 0, 0));
}



/* Services */

.services {
    margin: auto;
    width: 100%;
}



.service-box {
    display: flex;
    margin: 0 auto;
    align-items: center;
    justify-content: space-around;
}

.service-box .box {
    width: 20%;
    margin: 0 auto;
    padding: 60px;
    object-fit: cover;
}

.service-box .box img {
    width: 100%;
    height: 70%;
    border-radius: 50%;
    box-shadow: 10px 10px 20px grey;
}

.service-box .box h3 {
    margin-top: 10px;
    font-size: 24px;
    text-align: center;
    color: #0071bc;
}


/* About Content */
.about-content {
    margin: auto;
    width: 100%;
    display: grid;
    grid-template-columns: 50% 50%;
    grid-gap: 10px;
    padding-bottom: 5%;
}

.a-left,
.a-right {
    width: 100%;
    padding: 20px 40px;
}

.a-left p,
.a-right p {
    color: #666;
    font-size: 20px;
    font-weight: 500;
    padding: 20px 80px;
    text-align: center;
    justify-content: center;
    line-height: 1.5;
}

.a-left li,
.a-right li {
    margin: auto;
    text-align: center;
    color: #666;
    font-size: 17px;
}

/* Media Query */
@media only screen and (max-width: 1200px) {
    .aboutContainer h1 {
        font-size: 38px;
    }

    .aboutContainer h2 {
        font-size: 27px;
        padding: 30px 0 7px;
    }

    .m-left p {
        font-size: 17px;
        padding: 15px 70px;
    }

    .m-left,
    .m-right {
        padding: 15px 35px;
    }

    .m-right img {
        width: 85%;
        height: 75%;
        margin: 30px 27px;
        border-radius: 8px;
    }

    .service-box .box h3 {
        font-size: 20px;
    }

    .service-box .box {
        width: 19%;
        padding: 50px;
    }

    .a-left,
    .a-right {
        padding: 15px 30px;
    }

    .a-left p,
    .a-right p {

        font-size: 15px;
        padding: 17px 70px;
    }

    .a-left li,
    .a-right li {

        font-size: 12px;
    }
}

@media only screen and (max-width: 992px) {
    .aboutContainer h1 {
        font-size: 33px;
    }

    .service-box {
        flex-wrap: wrap;
    }

    .service-box .box {
        padding: 50px;
    }

    .aboutContainer h2 {
        font-size: 25px;
        padding: 25px 0 5px;
    }

    .m-left p {
        font-size: 15px;
        padding: 10px 60px;
    }

    .m-left,
    .m-right {
        padding: 12px 30px;
    }

    .m-right img {
        width: 80%;
        height: 70%;
        margin: 25px 22px;
        border-radius: 7px;
    }

    .service-box .box {
        width: 17%;
        padding: 25px;
    }

    .service-box .box img {
        height: 60%;
        box-shadow: 6px 6px 12px grey;
    }

    .service-box .box h3 {
        font-size: 18px;
    }

    .a-left,
    .a-right {
        padding: 12px 20px;
    }

    .a-left p,
    .a-right p {

        font-size: 15px;
        padding: 15px 65px;
    }

    .a-left li,
    .a-right li {

        font-size: 13px;
    }
}

@media only screen and (max-width: 768px) {
    .aboutContainer h1 {
        font-size: 28px;
    }

    .aboutContainer h2 {
        font-size: 20px;
        padding: 20px 0 3px;
    }

    .m-left p {
        font-size: 12px;
        padding: 8px 50px;
    }

    .m-left,
    .m-right {
        padding: 10px 20px;
    }

    .m-right img {
        width: 75%;
        height: 65%;
        margin: 20px 18px;
        box-shadow: 5px 5px 10px grey;
    }

    .service-box .box img {
        height: 50%;
    }

    .service-box .box h3 {
        font-size: 12px;
    }

    .a-left,
    .a-right {
        padding: 8px 10px;
    }

    .a-left p,
    .a-right p {

        font-size: 12px;
        padding: 10px 50px;
    }

    .a-left li,
    .a-right li {

        font-size: 12px;
    }
}

@media only screen and (max-width: 600px) {
    .aboutContainer h1 {
        font-size: 23px;
    }

    .aboutContainer h2 {
        font-size: 15px;
        padding: 20px 0 3px;
    }

    .m-left p {
        font-size: 12px;
        padding: 8px 50px;
    }

    .m-left,
    .m-right {
        padding: 5px 10px;
    }

    .service-box .box {
        padding: 20px;
    }

    .service-box .box h3 {
        font-size: 12px;
    }

    .about-content {
        margin: auto;
        display: flex;
        flex-wrap: wrap;
    }
}

@media only screen and (max-width: 550px) {
    .mainContent {
        display: flex;
        flex-direction: column;
    }

    .companyImg {
        margin: auto;
    }

    .m-right img {
        display: block;
        width: 70%;
        height: 80%;
        margin: 10px 5px;
        margin-bottom: 20px;
        justify-content: center;
        box-shadow: 5px 5px 15px grey;
        border-radius: 10px;
    }

    .service-box .box {
        padding: 20px 30px;
        width: 35%;
    }


}