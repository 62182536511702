.carousel-control-next-icon,
.carousel-control-prev-icon {
    height: 4rem;
    background-color: #F15A24;
}

.homeContainer {
    justify-content: center;
}


.homeRight,
.homeLeft {
    margin-top: 40px;
    padding-bottom: 40px;
}

.homeContent {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100%;
    padding-left: 50px;
}

.homeContent h1,
h2 {
    line-height: 2;
}

.logoImage {
    height: 450px;
    padding: 3%;
    border-radius: 50%;
    border: 3px solid #ECF0F3;
    border-radius: 60%;
    box-shadow: 13px 13px 20px #cbcde1,
        -13px -13px 20px #fff;
    ;
}

/* Media Query */
@media only screen and (max-width: 1200px) {
    .homeContent {
        padding-left: 40px;
    }

    .logoImage {
        height: 350px;
        padding: 2.5%;
    }
}

@media only screen and (max-width: 992px) {

    .homeRight,
    .homeLeft {
        margin-top: 20px;
        padding: 0;
    }

    .homeRight {
        padding-bottom: 30px;
    }

    .homeContent {
        padding: 0;
    }

}

@media only screen and (max-width: 600px) {
    .logoImage {
        height: 300px;
        padding: 2%;
    }
}

@media only screen and (max-width: 550px) {
    .logoImage {
        height: 200px;
        padding: 1.5%;
    }
}

@media only screen and (max-width: 360px) {
    .logoImage {
        height: 140px;
        padding: 1%;
    }
}