.footer {
  background-color: #000000;
  color: #ffffff;
  bottom: 0;
}
ol,
ul {
  list-style: none;
  margin: 0;
  padding: 0;
}

.footer a, .footer p{
  text-decoration: none;
  color: #ffffff;
}
a:hover {
  opacity: 0.5;
}
.ft-title {
  color: #F15A24;
  font-size: 18px;
}

li {
    list-style: none;
}