@font-face {
  font-family: "Proxima Nova";
  src: local("Proxima Nova"), url("./fonts/ProximaNova.woff") format("woff");
}

@font-face {
  font-family: "Proxima Nova Bold";
  src: local("Proxima Nova"), url("./fonts/ProximaNovaBold.woff") format("woff");
}

body,
button,
input,
p,
textarea {
  font-family: "Proxima Nova";
  margin: 0;
  padding: 0;
  color: #666666;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "Proxima Nova Bold";
}

.ProximaNova {
  font-family: "Proxima Nova", sans-serif;
}

.primary {
  color: #F15A24;
}

.secondary {
  color: #0071BC;
}