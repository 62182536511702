* {
    padding: 0;
    margin: 0;
    top: 0;
    overflow-x: hidden;
}

html {
    scroll-behavior: smooth;
}

.header {
    justify-content: center;
    margin: auto;
}

/* Header */
.header h1 {
    color: #0071bc;
    font-weight: bold;
    font-size: 40px;
    text-align: center;
    padding: 20px;
    margin: 0 auto;

}


/* Carousel */
.carousel-control-next-icon,
.carousel-control-prev-icon {
    height: 4rem;
    background-color: #F15A24;
}

.Container img {
    width: 20%;
    margin: 40px 35px;
    box-shadow: 10px 10px 20px grey;
    border-radius: 10px;

}

.Container h3 {
    color: #f15a24;
    font-size: 30px;
    font-weight: 900;
    text-align: center;
}

.Container p {
    color: #666;
    font-size: 20px;
    font-weight: 500;
    padding: 20px 80px;
    text-align: center;
    justify-content: center;
    line-height: 1.5;
}


/* media query */
@media only screen and (max-width: 1200px) {
    .Container h1 {
        font-size: 38px;
    }

    .Container h3 {
        font-size: 25px;
    }

    .Container p {
        font-size: 17px;
        padding: 15px 70px;
    }

    .Container img {
        margin: 30px 27px;
    }
}

@media only screen and (max-width: 992px) {
    .Container h1 {
        font-size: 33px;
    }

    .Container h3 {
        font-size: 20px;
    }

    .Container p {
        font-size: 15px;
        padding: 10px 60px;
    }

    .Container img {
        margin: 25px 22px;

    }
}

@media only screen and (max-width: 768px) {
    .Container h1 {
        font-size: 28px;
    }

    .Container h3 {
        font-size: 17px;
    }

    .Container p {
        font-size: 10px;
        padding: 8px 50px;
    }

    .Container img {
        margin: 20px 18px;

    }
}

@media only screen and (max-width: 600px) {
    .Container h1 {
        font-size: 23px;
    }

    .Container h3 {
        font-size: 15px;
    }

    .Container p {
        font-size: 12px;
        padding: 8px 50px;
    }
}